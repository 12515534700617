import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { type Dayjs } from "dayjs";
import { useSearchParams } from "react-router-dom";

import { type Order } from "@fitness-app/data-models/entities/Order";

import { supabase } from "~/store/initializeStore";

const ITEMS_PER_PAGE = 25;

export type OrderWithProductName = Order & { productInfo: { name: string } };

export const useProductsTransactions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRange, setSelectedRange] = useState<[Dayjs | null, Dayjs | null] | null>(null);
  const [searchTerm, setSearchTerm] = useState(searchParams.get("searchTerm") || "");
  const [currentPage, setCurrentPage] = useState(
    searchParams.get("currentPage") ? Number(searchParams.get("currentPage")) : 1,
  );
  const queryClient = useQueryClient();
  const [filteredProducts, setFilteredProducts] = useState<string[]>(
    searchParams.get("products") ? searchParams.get("products")?.split(",") || [] : [],
  );
  const [filteredStatuses, setFilteredStatuses] = useState<Order["status"][]>(
    searchParams.get("status") ? (searchParams.get("status")?.split(",") as Order["status"][]) || [] : [],
  );

  useEffect(() => {
    setSearchParams((prev) => ({
      ...prev,
      tab: "productsTransactions",
      currentPage,
      searchTerm,
      status: filteredStatuses ? filteredStatuses.join(",") : [],
      products: filteredProducts ? filteredProducts.join(",") : [],
    }));
  }, [currentPage, filteredProducts, filteredStatuses, searchTerm, filteredProducts]);

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["productsTransactions", currentPage, searchTerm, filteredProducts, filteredStatuses],
    keepPreviousData: true,
    queryFn: async () => {
      const start = (currentPage - 1) * ITEMS_PER_PAGE;
      const stop = currentPage * ITEMS_PER_PAGE - 1;

      let query = supabase
        .from("product_order")
        .select(
          "id, client, clientEmail,createdAt,updatedAt,status,paymentGateway, installments, paymentMethodType,paymentType,price,product,productInfo:product(name)",
          { count: "exact" },
        )
        .order("createdAt", { ascending: false });

      if (filteredStatuses?.length) {
        query = query.in("status", filteredStatuses);
      }

      if (filteredProducts?.length) {
        query = query.in("product", filteredProducts);
      }

      if (searchTerm) {
        query = query.or(`clientEmail.ilike.%${searchTerm}%,client->>lastName.ilike.%${searchTerm}%`);
      }

      const { error, data, count } = await query
        .range(start, stop)
        .limit(ITEMS_PER_PAGE)
        .returns<OrderWithProductName[]>();

      if (error) {
        throw new Error(error.message);
      }
      return {
        list: data,
        count,
      };
    },
  });

  const clearSearch = () => {
    void queryClient.invalidateQueries(["productsTransactions"]);

    setSearchTerm("");
    setCurrentPage(1);
  };

  return {
    selectedRange,
    setSelectedRange,
    searchTerm,
    setSearchTerm,
    data,
    clearSearch,
    isFetching,
    isLoading,
    currentPage,
    pageSize: ITEMS_PER_PAGE,
    pageSizeOptions: ["50"],
    showSizeChanger: false,
    setFilteredStatuses,
    setFilteredProducts,
    setCurrentPage,
    filteredProducts,
    filteredStatuses,
  };
};
