import React, { useState, type FunctionComponent } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { type RcFile } from "antd/lib/upload";
import { type UploadFile } from "antd/lib/upload/interface";
import deburr from "lodash.deburr";
import last from "lodash.last";
import { useTranslation } from "react-i18next";

import { getErrorMessage } from "@fitness-app/utils";

import { supabase } from "~/store/initializeStore";
import { Logger } from "~/utils/Logger";

export interface RcCustomRequestOptions {
  onProgress: (
    event: {
      percent: number;
    },
    file: RcFile,
  ) => void;
  onError: (error: Error, response?: unknown, file?: RcFile) => void;
  onSuccess: (response: object, file: RcFile) => void;
  data: object;
  filename: string;
  file: RcFile;
  withCredentials: boolean;
  action: string;
  headers: object;
}

interface OwnProps {
  storageRef: string;
  onChange?: (urls: { url: string; name: string; uid: string }[]) => void;
  onDelete?: (file: UploadFile<File>) => void;
  fileList?: { url: string; name: string; uid: string; protected?: boolean; contentType?: string }[];
  multiple?: boolean;
  disabled?: boolean;
  fileName?: string;
  idAsName?: boolean;
  bucket?: string;
  icon?: typeof UploadOutlined;
  className?: string;
  withContentType?: boolean;
}

type Props = OwnProps;

const beforeUpload = (file: RcFile, _: RcFile[], limitInMB = 40) => {
  const validationType =
    file.type === "application/pdf" ||
    file.type === "image/jpg" ||
    file.type === "image/gif" ||
    file.type === "image/png";
  const extension = last(file.name.split("."));
  const allowedExtension = [
    "txt",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "ppt",
    "pptx",
    "mp3",
    "mp4",
    "csv",
    "zip",
    "jpg",
    "jpeg",
  ];
  if (!extension || (!validationType && !allowedExtension.includes(extension))) {
    void message.error(
      "Rozszerzenie pliku jest niedopuszczalne, wyślij plik w formacie jpg/gif/png/pdf/txt/doc/docx/xls/xlsx/ppt/pptx/mp3/mp4/csv/zip.",
    );
  }
  const isToLarge = file.size / 1024 / 1024 > limitInMB;
  if (isToLarge) {
    void message.error(`Plik nie może być większy niż ${limitInMB}MB.`);
  }

  return Boolean((validationType || (extension && allowedExtension.includes(extension))) && !isToLarge);
};

interface File extends RcFile {
  url: string | null;
  protected?: boolean;
}

const EXPIRES_IN_20_YEARS = 631138519;

const normalizeFileName = (name: string) => deburr(name.replaceAll(" ", "_").replaceAll("-", "_")).replaceAll("~", "_");

const UploadField: FunctionComponent<Props> = ({
  storageRef,
  onChange,
  multiple,
  fileList: defaultFileList,
  fileName,
  onDelete,
  idAsName = false,
  bucket = "shared",
  disabled,
  withContentType = false,
}) => {
  const { t } = useTranslation("common");

  const [fileList, updateFileList] = useState<UploadFile<File & { protected?: boolean }>[]>(() => {
    if (defaultFileList?.length) {
      return defaultFileList.map(
        (item) =>
          ({
            url: item.url,
            name: item.name,
            contentType: item.contentType || null,
            size: 0,
            protected: item.protected,
            uid: item.uid,
            status: "done",
          }) as UploadFile<File & { protected?: boolean }>,
      );
    }

    return [];
  });

  const getFileName = (file: RcFile | UploadFile<File>) => {
    if (fileName) {
      return `${fileName}.${normalizeFileName(file.name.split(".").pop() || "empty-name")}`;
    }

    return idAsName ? file.uid : `${file.uid}-${normalizeFileName(file.name)}`;
  };

  const uploadToStorage = async ({ file, onSuccess }: RcCustomRequestOptions) => {
    // const ref = `https://${import.meta.env.VITE_SUPABASE_PROJECT_ID}.supabase.co/storage/v1/upload/resumable`;
    // const token =
    //   (await supabase.auth.getSession())?.data.session?.access_token || import.meta.env.VITE_SUPABASE_ANON_KEY;

    updateFileList((prev) => [
      ...prev,
      {
        type: file.type,
        name: file.name,
        uid: file.uid,
        lastModified: file.lastModified,
        size: file.size,
        percent: 0,
        status: "uploading",
      },
    ]);

    const ref = `${storageRef}/${getFileName(file)}`;

    try {
      const { error: uploadError } = await supabase.storage.from(bucket).upload(ref, file, {
        cacheControl: "3600",
        upsert: true,
      });

      if (uploadError) {
        void message.error(t(`Błąd podczas dodawania pliku: ${uploadError.message}`));
        updateFileList((prev) =>
          prev.map((savedFile) => {
            if (savedFile.uid === file.uid) {
              return {
                ...savedFile,
                error: uploadError.message,
                status: "error",
              };
            }

            return savedFile;
          }),
        );
        return;
      }

      let url = "";

      if (bucket === "shared") {
        const {
          data: { publicUrl },
        } = supabase.storage.from(bucket).getPublicUrl(ref);
        url = publicUrl;
      } else {
        const { data, error } = await supabase.storage.from(bucket).createSignedUrl(ref, EXPIRES_IN_20_YEARS);
        if (error) {
          throw new Error(error.message);
        }
        url = data.signedUrl;
      }

      onSuccess({}, file);
      const list: UploadFile<File>[] = [];

      updateFileList((prev) =>
        prev.map((savedFile) => {
          if (savedFile.uid === file.uid) {
            const updated = {
              ...savedFile,
              status: "done",
              url: url,
            } as UploadFile<File>;
            list.push(updated);
            return updated;
          }
          list.push(savedFile);
          return savedFile;
        }),
      );
      onChange?.(
        list
          .filter((item) => item.status === "done" && item.url)
          .map((item) => ({
            url: item.url ?? "",
            name: item.name ?? "",
            uid: item.uid,
            ...(withContentType ? { contentType: item.type } : {}),
          })),
      );
    } catch (e) {
      void message.error(t(`Błąd podczas dodawania pliku: ${getErrorMessage(e)}`));
      updateFileList((prev) =>
        prev.map((savedFile) => {
          if (savedFile.uid === file.uid) {
            return {
              ...savedFile,
              status: "error",
            };
          }

          return savedFile;
        }),
      );
    }

    // const upload = new tus.Upload(file, {
    //   endpoint: ref,
    //   retryDelays: [0, 3000, 5000, 10000, 20000],
    //   headers: {
    //     authorization: `Bearer ${token}`,
    //     "x-upsert": "true", // optionally set upsert to true to overwrite existing files
    //   },
    //   uploadDataDuringCreation: true,
    //   metadata: {
    //     bucketName: storageRef,
    //     objectName: file.name,
    //     contentType: file.type,
    //     cacheControl: "3600",
    //   },
    //   chunkSize: 6 * 1024 * 1024, // NOTE: it must be set to 6MB (for now) do not change it
    //   onError: function () {
    //     void message.error(t("validationErrors.cannotAddImage"));
    //     updateFileList((prev) =>
    //       prev.map((savedFile) => {
    //         if (savedFile.uid === file.uid) {
    //           return {
    //             ...savedFile,
    //             status: "error",
    //           };
    //         }
    //
    //         return savedFile;
    //       }),
    //     );
    //   },
    //   onProgress: function (bytesUploaded, bytesTotal) {
    //     const percentage = (bytesUploaded / bytesTotal) * 100;
    //
    //     updateFileList((prev) =>
    //       prev.map((savedFile) => {
    //         if (savedFile.uid === file.uid) {
    //           return {
    //             ...savedFile,
    //             percent: percentage,
    //           };
    //         }
    //
    //         return savedFile;
    //       }),
    //     );
    //   },
    //   onSuccess: function () {
    //     onSuccess({}, file);
    //     onChange?.(
    //       [...fileList.map((item) => item.url), upload.url || ""],
    //       [
    //         ...fileList,
    //         {
    //           status: "done",
    //           type: file.type,
    //           name: file.name,
    //           uid: file.uid,
    //           lastModified: file.lastModified,
    //           lastModifiedDate: file.lastModifiedDate,
    //           size: file.size,
    //           url: upload.url || "",
    //         },
    //       ],
    //     );
    //     updateFileList((prev) =>
    //       prev.map((savedFile) => {
    //         if (savedFile.uid === file.uid) {
    //           return {
    //             ...savedFile,
    //             status: "done",
    //             url: upload.url || "",
    //           };
    //         }
    //
    //         return savedFile;
    //       }),
    //     );
    //   },
    // });

    // // Check if there are any previous uploads to continue.
    // upload.findPreviousUploads().then(function (previousUploads) {
    //   // Found previous uploads so we select the first one.
    //   if (previousUploads.length) {
    //     upload.resumeFromPreviousUpload(previousUploads[0]);
    //   }
    //
    //   // Start the upload
    //   upload.start();
    // });

    // upload.start();

    // try {
    //   const formData = new FormData();
    //   formData.append("file", file, getFileName(file));
    //
    //   // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    //   const { data, error } = await supabase.functions.invoke<{ path: string; url: string }>("handle-avatar-upload", {
    //     body: formData,
    //   });
    //
    //   if (error || !data) {
    //     void message.error(t("validationErrors.cannotAddImage"));
    //     updateFileList((prev) =>
    //       prev.map((savedFile) => {
    //         if (savedFile.uid === file.uid) {
    //           return {
    //             ...savedFile,
    //             status: "error",
    //           };
    //         }
    //
    //         return savedFile;
    //       }),
    //     );
    //
    //     return;
    //   }
    //
    //   onSuccess({}, file);
    //   onChange?.(
    //     [...fileList.map((item) => item.url), data.url],
    //     [
    //       ...fileList,
    //       {
    //         status: "done",
    //         type: file.type,
    //         name: file.name,
    //         uid: file.uid,
    //         lastModified: file.lastModified,
    //         lastModifiedDate: file.lastModifiedDate,
    //         size: file.size,
    //         url: data.url,
    //       },
    //     ],
    //   );
    //   updateFileList((prev) =>
    //     prev.map((savedFile) => {
    //       if (savedFile.uid === file.uid) {
    //         return {
    //           ...savedFile,
    //           status: "done",
    //           url: data.url,
    //         };
    //       }
    //
    //       return savedFile;
    //     }),
    //   );
    // } catch (error) {
    //   void message.error(t("validationErrors.cannotAddImage"));
    //   updateFileList((prev) =>
    //     prev.map((savedFile) => {
    //       if (savedFile.uid === file.uid) {
    //         return {
    //           ...savedFile,
    //           status: "error",
    //         };
    //       }
    //
    //       return savedFile;
    //     }),
    //   );
    // }
  };

  const onRemove = async (file: UploadFile<File>) => {
    updateFileList((prev) => prev.filter((savedFile) => savedFile.url !== file.url));
    onChange?.(
      fileList
        .filter((item) => item.url !== file.url)
        .map((item) => ({
          url: item.url ?? "",
          name: item.name ?? "",
          uid: item.uid,
          ...(withContentType ? { contentType: item.type } : {}),
        })),
    );
    try {
      if (file.uid && (file.status === "done" || file.uid === "-1")) {
        if (file.uid === "-1" && file.url) {
          if (fileName) {
            await supabase.storage.from(bucket).remove([`${storageRef}/${getFileName(file)}`]);
          }
          // const ref = firebase.storage().refFromURL(file.url);
          // await ref.delete();
          onDelete?.(file);
        } else {
          await supabase.storage.from(bucket).remove([`${storageRef}/${getFileName(file)}`]);
          onDelete?.(file);
        }
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  return (
    <>
      <Upload
        name="file"
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        fileList={fileList}
        multiple={multiple}
        disabled={disabled}
        // @ts-expect-error ignore
        customRequest={uploadToStorage}
        // @ts-expect-error ignore
        showUploadList={fileList?.[0]?.protected ? { showRemoveIcon: false } : undefined}
      >
        <Button icon={<UploadOutlined />} disabled={disabled}>
          Upload
        </Button>
      </Upload>
    </>
  );
};

export default UploadField;
