import React from "react";
import { Button, Card, Input, Space, Table, Tag, type TableProps } from "antd";
import dayjs from "dayjs";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  OrderStatusOption,
  type Order,
  type OrderItem,
  type OrderStatus,
} from "@fitness-app/data-models/entities/Order";
import { PaymentTypeOption } from "@fitness-app/data-models/entities/Product";

import { useProductsList } from "~/modules/Dashboard/AppStats/hooks/useProductsList";
import InvoiceDataInfo from "~/modules/Products/components/InvoiceDataInfo/InvoiceDataInfo";
import {
  useProductsTransactions,
  type OrderWithProductName,
} from "~/modules/Products/ProductsTransactions/useProductsTransactions";
import { tagColorMapper } from "~/modules/Products/utils/tagColorMapper";

const statusWithProductClient: Order["status"][] = [
  OrderStatusOption.Paid,
  OrderStatusOption.Finalized,
  OrderStatusOption.Imported,
  OrderStatusOption.Refunded,
];

export const ProductsTransactions = (): React.ReactElement => {
  const { t } = useTranslation(["products", "common", "client"]);
  const { list } = useProductsList();
  const {
    data,
    clearSearch,
    setSearchTerm,
    searchTerm,
    currentPage,
    pageSize,
    filteredStatuses,
    setCurrentPage,
    setFilteredStatuses,
    filteredProducts,
    setFilteredProducts,
    isFetching,
    isLoading,
  } = useProductsTransactions();

  const onSearch = (value: string) => {
    const trimmed = value.trim();
    if (!trimmed) {
      if (searchTerm) {
        clearSearch();
      }
      return;
    }
    setSearchTerm(trimmed);
    setCurrentPage(1);
  };

  const handleChange: TableProps<OrderWithProductName>["onChange"] = (pagination, filters) => {
    if (!isEqual(filteredStatuses, filters.status)) {
      setCurrentPage(1);
      setFilteredStatuses(filters.status as Order["status"][]);
    } else if (!isEqual(filteredProducts, filters.productInfo)) {
      setCurrentPage(1);
      setFilteredProducts(filters.productInfo as string[]);
    } else if (pagination.current && pagination.current !== currentPage) {
      setCurrentPage(pagination.current);
    }
  };

  return (
    <Card>
      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Input.Search
            placeholder={t<string>("search.placeholder")}
            onSearch={onSearch}
            allowClear
            style={{ width: 300, margin: "20px 0" }}
          />
          {filteredProducts?.length || filteredStatuses?.length ? (
            <Button
              onClick={() => {
                setCurrentPage(1);
                setFilteredProducts([]);
                setFilteredStatuses([]);
              }}
              type="link"
            >
              {t("search.reset")}
            </Button>
          ) : null}
        </div>

        <Table<OrderWithProductName>
          rowKey="id"
          dataSource={data?.list || []}
          scroll={{ x: true }}
          loading={isLoading || isFetching}
          onChange={handleChange}
          pagination={{
            current: currentPage,
            pageSize,
            total: data?.count || 0,
            pageSizeOptions: ["50"],
            showSizeChanger: false,
          }}
          expandedRowRender={(order) => <InvoiceDataInfo orderClient={order.client} />}
        >
          <Table.Column<OrderWithProductName>
            title={t("productTransactions.tableHeader.index")}
            width={50}
            dataIndex="index"
            key="index"
            render={(name, row, i) => (currentPage > 1 ? (currentPage - 1) * pageSize + i + 1 : i + 1)}
          />
          <Table.Column<OrderWithProductName>
            title={t("productTransactions.tableHeader.name")}
            dataIndex="name"
            key="name"
            render={(_, row) => (row.client.lastName ? `${row.client.lastName} ${row.client.firstName}` : "-")}
            // sorter={(a, b) => a.client.lastName?.localeCompare(b.client.lastName)}
          />
          <Table.Column<OrderWithProductName>
            title={t("productTransactions.tableHeader.email")}
            dataIndex="clientEmail"
            key="clientEmail"
            // sorter={(a, b) => a.clientEmail.length - b.clientEmail.length}
            render={(email, row) =>
              statusWithProductClient.includes(row.status) ||
              row.connectedSubscription ||
              row.paymentType === PaymentTypeOption.Recurring ? (
                <Link to={`/products/${row.product}/clients/${email}`}>{email}</Link>
              ) : (
                email
              )
            }
          />
          <Table.Column<OrderWithProductName>
            title={t("productTransactions.tableHeader.createdAt")}
            dataIndex="createdAt"
            key="createdAt"
            render={(createdAt: string) => dayjs(createdAt).format("DD.MM.YYYY HH:mm")}
            defaultSortOrder="descend"
            // sorter={(a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix()}
          />
          <Table.Column<OrderWithProductName>
            title={t("productTransactions.tableHeader.productName")}
            dataIndex="productInfo"
            key="productInfo"
            filteredValue={filteredProducts}
            render={(_, row) => <Link to={`/products/${row.product}/`}>{row.productInfo.name}</Link>}
            filters={list.map((item) => ({
              value: item.value,
              text: item.label,
            }))}
          />
          <Table.Column<OrderWithProductName>
            title={t("productTransactions.tableHeader.price")}
            dataIndex="price"
            key="price"
            render={(price: OrderItem) =>
              price ? (
                <b>
                  {(price.amount ? price.amount / 100 : 0).toFixed(2)} {price.currency?.toUpperCase()}
                </b>
              ) : (
                "-"
              )
            }
            // sorter={(a, b) =>
            //   (!a.price || a.price.free ? 0 : a.price.amount) - (!b.price || b.price.free ? 0 : b.price.amount)
            // }
          />
          <Table.Column<OrderWithProductName>
            title={t("productTransactions.tableHeader.status")}
            dataIndex="status"
            key="status"
            filteredValue={filteredStatuses as OrderStatus[]}
            filters={Object.values(OrderStatusOption).map((status) => ({
              value: status,
              text: t(`productTransactions.type.${status.toLowerCase()}`),
            }))}
            render={(type: Order["status"]) => (
              <Tag color={tagColorMapper[type || OrderStatusOption.Created]}>
                {t(`productTransactions.type.${type?.toLowerCase() || "paid"}`)}
              </Tag>
            )}
          />
          <Table.Column<OrderWithProductName>
            title={t("productClients.tableHeader.paymentType")}
            dataIndex="paymentType"
            key="paymentType"
            render={(type, record) => <Tag>{t(`client:type.${record.installments ? "installments" : type}`)}</Tag>}
          />

          <Table.Column<OrderWithProductName>
            title={t("productTransactions.tableHeader.paymentGateway")}
            dataIndex="paymentGateway"
            key="paymentGateway"
            render={(paymentGateway: string, record) =>
              paymentGateway
                ? `${t(`productTransactions.paymentGateway.${paymentGateway.toLowerCase()}`)}${
                    record.paymentMethodType &&
                    record.paymentMethodType !== paymentGateway.toLowerCase() &&
                    record.paymentMethodType !== "manualPayment"
                      ? ` (${t(`productTransactions.paymentMethodType.${record.paymentMethodType}`)})`
                      : ""
                  }`
                : "-"
            }
          />
          <Table.Column<OrderWithProductName>
            title={t("productClients.tableHeader.options")}
            dataIndex="action"
            key="action"
            render={(_, row) =>
              statusWithProductClient.includes(row.status) ||
              row.connectedSubscription ||
              row.paymentType === PaymentTypeOption.Recurring ? (
                <Link to={`/products/${row.product}/clients/${row.clientEmail}`}>{t(`common:details`)}</Link>
              ) : (
                "-"
              )
            }
          />
        </Table>
      </Space>
    </Card>
  );
};
