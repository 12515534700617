import round from "lodash.round";

import { type Dish } from "@fitness-app/data-models/entities/Dish";
import { type IngredientWithPortion, type Nutrients } from "@fitness-app/data-models/entities/Ingredient";
import { MealIngredientStatus, type DishInMeal, type Meal } from "@fitness-app/data-models/entities/MealsPlan";

import { calculateForPortion, countCaloriesByIngredients } from "./countNutrients";
import { getNutrientsInPortion, sumNutrients } from "./sumNutrients";

export const getNutrientSummary = (
  prevValue: number,
  dishOrIngredient: DishInMeal | IngredientWithPortion | Dish,
  key: keyof Nutrients,
) => {
  if (dishOrIngredient.type === "ingredient") {
    return prevValue + getNutrientsInPortion(dishOrIngredient, key);
  }
  return (
    prevValue +
    calculateForPortion(sumNutrients(dishOrIngredient.ingredients, key), {
      portions: dishOrIngredient.portions ?? 1,
      portionCount: dishOrIngredient.portionCount ?? 1,
    })
  );
};

export const getCaloriesSummary = (prevValue: number, dishOrIngredient: DishInMeal | IngredientWithPortion | Dish) => {
  if (dishOrIngredient.type === "ingredient") {
    return prevValue + round((dishOrIngredient.portion * dishOrIngredient.nutrients.calories) / 100, 1);
  }
  return (
    prevValue +
    countCaloriesByIngredients(dishOrIngredient.ingredients) *
      ((dishOrIngredient.portionCount ?? 1) / (dishOrIngredient.portions ?? 1))
  );
};

export const reduceDishIngredientsNutrients = (
  dishesOrIngredients: (DishInMeal | IngredientWithPortion | Dish)[],
  overrides?: Record<
    string,
    {
      protein: number;
      carbs: number;
      fat: number;
      calories: number;
    }
  >,
) => {
  return dishesOrIngredients.reduce(
    (prevValue, current) => {
      const override = overrides?.[current.id];

      if (current.type === "dish" && current.source === "ai_analyzer" && current.ingredients.length === 0) {
        return {
          calories: prevValue.calories + (current.calories ?? 0),
          protein: prevValue.protein + (current.metadata?.estimatedMacros?.protein || 0),
          carbs: prevValue.carbs + (current.metadata?.estimatedMacros?.carbs || 0),
          fat: prevValue.fat + (current.metadata?.estimatedMacros?.fat || 0),
        };
      }

      return {
        calories: override
          ? round(prevValue.calories + override.calories, 1)
          : round(getCaloriesSummary(prevValue.calories, current), 1),
        protein: override
          ? round(prevValue.protein + override.protein, 1)
          : round(getNutrientSummary(prevValue.protein, current, "protein"), 1),
        carbs: override
          ? round(prevValue.carbs + override.carbs, 1)
          : round(getNutrientSummary(prevValue.carbs, current, "carbohydrates"), 1),
        fat: override
          ? round(prevValue.fat + override.fat, 1)
          : round(getNutrientSummary(prevValue.fat, current, "fat"), 1),
      };
    },
    {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
    },
  );
};

export const reduceMealsNutrients = (
  meals: Meal[],
  onlyEaten = false,
  overrides?: Record<
    string,
    {
      protein: number;
      carbs: number;
      fat: number;
      calories: number;
    }
  >,
): { calories: number; protein: number; carbs: number; fat: number } => {
  const dishesOrIngredients = meals
    .flatMap((meal) =>
      onlyEaten ? meal.dishes.filter((item) => item.status === MealIngredientStatus.EATEN) : meal.dishes,
    )
    .flat();

  return reduceDishIngredientsNutrients(dishesOrIngredients, overrides);
};
