import { useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Alert, Dropdown, Menu } from "antd";
import { type Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { productClientActions, productOrdersActions } from "@fitness-app/app-store";
import { OrderStatusOption, type ClientInvoiceData, type Order } from "@fitness-app/data-models/entities/Order";
import { PaymentClient } from "@fitness-app/data-models/entities/Product";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useEntityChange } from "~/hooks/useEntityChange";
import InvoiceFormData, { type ExtendedModel } from "~/modules/Products/components/InvoiceFormData/InvoiceFormData";
import { useAppDispatch } from "~/store/initializeStore";
import { useConfirmManualPayment } from "../../hooks/useConfirmManualPayment";
import { useMarkManualPaymentAsFailed } from "../../hooks/useMarkManualPaymentAsFailed";

interface IssueInvoiceProps {
  order: Order;
  selectedMonth: Dayjs;
  ownerId?: string;
}

const IssueInvoice = ({ order, selectedMonth, ownerId }: IssueInvoiceProps) => {
  const { t } = useTranslation(["products", "client", "common"]);
  const [showInvoiceForm, toggleInvoiceForm] = useState<false | "issueReceipt" | "issueInvoice">(false);
  const [loader, onSuccess, _, onStart, onEnd] = useEntityChange(() => {
    toggleInvoiceForm(false);
  });
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string | null | { message: string; details: string }>(null);
  const { mutate: markManualPaymentAsFailed } = useMarkManualPaymentAsFailed(selectedMonth, ownerId ?? "");
  const { mutate: markManualPaymentAsPaid } = useConfirmManualPayment(selectedMonth, ownerId ?? "");
  const queryClient = useQueryClient();

  const updateInvoiceData = async (data: ClientInvoiceData | ExtendedModel) => {
    onStart();
    setErrorMessage(null);
    try {
      if ("updateClientData" in data) {
        const { sellDate, issueDate, updateClientData, ...rest } = data;
        await dispatch(
          productClientActions.updateInvoiceData({
            payload: {
              invoiceData: rest,
              clientEmail: order.clientEmail,
              updateClientData,
              sellDate,
              issueDate,
              orderId: order.id,
            },
          }),
        );
      }

      await dispatch(
        productClientActions.issueInvoice({
          ownerId: order.owner ?? "",
          productId: order.product,
          orderId: order.id,
          updateClientData: "updateClientData" in data ? data.updateClientData : false,
          sendInvoiceToClient: "sendInvoiceToClient" in data ? data.sendInvoiceToClient : false,
          issueReceipt: showInvoiceForm === "issueReceipt",
        }),
      ).unwrap();

      onSuccess();
      void queryClient.invalidateQueries(["productsTransactions"]);
      void dispatch(productOrdersActions.fetchOrders({ productId: order.product, selectedMonth, ownerId }));
      toggleInvoiceForm(false);
    } catch (e) {
      if (e instanceof Error) {
        if ("code" in e && e.code === "invalid-argument") {
          setErrorMessage(JSON.parse(e.message) as { message: string; details: string });
        } else {
          setErrorMessage(e.message);
        }
      }

      onEnd();
    }
  };

  if (order.orderForImport || (order.connectedInvoice && "number" in order.connectedInvoice)) {
    if (order.paymentGateway === PaymentClient.MANUAL && order.status === OrderStatusOption.Created) {
      return (
        <div className="ml-2 flex-1 text-right">
          <Dropdown
            overlay={
              <Menu
                items={[
                  {
                    key: "markAsPaid",
                    label: t("client:invoice.markAsPaid"),
                    onClick: () => {
                      markManualPaymentAsPaid({
                        productId: order.product,
                        orderId: order.id,
                        clientEmail: order.clientEmail,
                      });
                    },
                  },
                  {
                    key: "markAsFailed",
                    danger: true,
                    label: t("client:invoice.markAsFailed"),
                    onClick: () => {
                      markManualPaymentAsFailed({ productId: order.product, orderId: order.id });
                    },
                  },
                ]}
              />
            }
          >
            <SettingOutlined />
          </Dropdown>
          <ModalForm<ExtendedModel>
            open={Boolean(showInvoiceForm)}
            onCancel={() => toggleInvoiceForm(false)}
            title={
              showInvoiceForm === "issueReceipt"
                ? t("client:invoice.issueReceiptTitle")
                : t("client:invoice.issueInvoiceTitle")
            }
            okText={
              showInvoiceForm === "issueReceipt" ? t("client:invoice.issueReceipt") : t("client:invoice.issueInvoice")
            }
            loading={!!loader}
          >
            <InvoiceFormData onSubmit={updateInvoiceData} model={order.client} withInvoiceMetadata order={order}>
              {errorMessage && (
                <Alert
                  showIcon
                  style={{ marginBottom: 20 }}
                  type="error"
                  message={t("client:invoice.errorTitle")}
                  description={
                    typeof errorMessage === "string" ? (
                      errorMessage
                    ) : (
                      <div>
                        <div>{errorMessage.message}</div>
                        <div>
                          {errorMessage.details
                            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                              (JSON.parse(errorMessage.details)?.message as string) || "Unknown error"
                            : "Unknown error"}
                        </div>
                      </div>
                    )
                  }
                />
              )}
            </InvoiceFormData>
          </ModalForm>
        </div>
      );
    }
    return null;
  }

  return (
    <div className="ml-2 flex-1 text-right">
      <Dropdown
        overlay={
          <Menu
            items={[
              ...(order.paymentGateway === PaymentClient.MANUAL && order.status === OrderStatusOption.Created
                ? [
                    {
                      key: "markAsPaid",
                      label: t("client:invoice.markAsPaid"),
                      onClick: () => {
                        markManualPaymentAsPaid({
                          productId: order.product,
                          orderId: order.id,
                          clientEmail: order.clientEmail,
                        });
                      },
                    },
                    {
                      key: "markAsFailed",
                      danger: true,
                      label: t("client:invoice.markAsFailed"),
                      onClick: () => {
                        markManualPaymentAsFailed({ productId: order.product, orderId: order.id });
                      },
                    },
                  ]
                : []),
              {
                key: "2",
                label:
                  order.connectedInvoice && "errorMessage" in order.connectedInvoice
                    ? t("client:invoice.retryReceiptIssue")
                    : t("client:invoice.issueReceipt"),
                onClick: () => toggleInvoiceForm("issueReceipt"),
              },
              {
                key: "1",
                label:
                  order.connectedInvoice && "errorMessage" in order.connectedInvoice
                    ? t("client:invoice.retryInvoiceIssue")
                    : t("client:invoice.issueInvoice"),
                onClick: () => toggleInvoiceForm("issueInvoice"),
              },
            ]}
          />
        }
      >
        <SettingOutlined />
      </Dropdown>
      <ModalForm<ExtendedModel>
        open={Boolean(showInvoiceForm)}
        onCancel={() => toggleInvoiceForm(false)}
        title={
          showInvoiceForm === "issueReceipt"
            ? t("client:invoice.issueReceiptTitle")
            : t("client:invoice.issueInvoiceTitle")
        }
        okText={
          showInvoiceForm === "issueReceipt" ? t("client:invoice.issueReceipt") : t("client:invoice.issueInvoice")
        }
        loading={!!loader}
      >
        <InvoiceFormData onSubmit={updateInvoiceData} model={order.client} withInvoiceMetadata order={order}>
          {errorMessage && (
            <Alert
              showIcon
              style={{ marginBottom: 20 }}
              type="error"
              message={t("client:invoice.errorTitle")}
              description={
                typeof errorMessage === "string" ? (
                  errorMessage
                ) : (
                  <div>
                    <div>{errorMessage.message}</div>
                    <div>
                      {errorMessage.details
                        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                          (JSON.parse(errorMessage.details)?.message as string) || "Unknown error"
                        : "Unknown error"}
                    </div>
                  </div>
                )
              }
            />
          )}
        </InvoiceFormData>
      </ModalForm>
    </div>
  );
};

export default IssueInvoice;
