import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { type Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { productOrdersActions } from "@fitness-app/app-store";

import { productApi, useAppDispatch } from "~/store/initializeStore";

type MarkManualPaymentAsFailedPayload = {
  productId: string;
  orderId: string;
};

export const useMarkManualPaymentAsFailed = (selectedMonth: Dayjs, ownerId: string) => {
  const { t } = useTranslation("products");
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const key = "markManualPaymentAsFailed";

  return useMutation({
    mutationFn: async (payload: MarkManualPaymentAsFailedPayload) => {
      message.loading({ content: "Trwa przetwarzanie operacji...", key });
      const response = await productApi.post("/manual-payment/failed", payload);
      return response.data;
    },
    onSuccess: (_, payload) => {
      message.destroy(key);
      void message.success(t("messages.success.manualPaymentMarkedAsFailed"));
      // Invalidate relevant queries to refetch updated data
      void queryClient.invalidateQueries(["productsTransactions"]);
      void dispatch(productOrdersActions.fetchOrders({ productId: payload.productId, selectedMonth, ownerId }));
    },
    onError: () => {
      void message.destroy(key);
      void message.error(t("messages.error.manualPaymentMarkingAsFailed"));
    },
  });
};
