import React, { memo, useEffect, useMemo, useState, type FunctionComponent } from "react";
import { CheckOutlined, FilterOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Drawer,
  Dropdown,
  Empty,
  List,
  Space,
  Tabs,
  type DropdownProps,
  type MenuProps,
  type TabsProps,
} from "antd";
import { useTranslation } from "react-i18next";

import { notificationsActions, RequestStatus } from "@fitness-app/app-store";
import {
  NotificationType,
  type ClientNotifications,
  type OwnerNotifications,
  type TrainerNotifications,
} from "@fitness-app/data-models/entities/Notification";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import NotificationItem from "~/modules/Dashboard/NotificationsDrawer/NotificationItem";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  isOpen: boolean;
  toggleDrawer: () => void;
}

type Props = OwnProps;

// eslint-disable-next-line react/display-name
const NotificationFilter = memo(
  ({
    selectedKeys,
    setSelectedKeys,
  }: {
    selectedKeys: NotificationType[];
    setSelectedKeys: (keys: NotificationType[]) => void;
  }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation(["dashboard", "common"]);
    const { isTrainer } = useUserRole();
    const filters = [
      ...(isTrainer
        ? [
            {
              key: NotificationType.ClientPurchaseProduct,
              label: (
                <Checkbox
                  onClick={(ev) => ev.stopPropagation()}
                  checked={selectedKeys.includes(NotificationType.ClientPurchaseProduct)}
                >
                  {t(`notificationTypeTitle.${NotificationType.ClientPurchaseProduct}`)}
                </Checkbox>
              ),
            },
            {
              key: NotificationType.ProductPaymentRequiredAction,
              label: (
                <Checkbox
                  onClick={(ev) => ev.stopPropagation()}
                  checked={selectedKeys.includes(NotificationType.ProductPaymentRequiredAction)}
                >
                  {t(`notificationTypeTitle.${NotificationType.ProductPaymentRequiredAction}`)}
                </Checkbox>
              ),
            },
            {
              key: NotificationType.ClientRenewedSubscription,
              label: (
                <Checkbox
                  onClick={(ev) => ev.stopPropagation()}
                  checked={selectedKeys.includes(NotificationType.ClientRenewedSubscription)}
                >
                  {t(`notificationTypeTitle.${NotificationType.ClientRenewedSubscription}`)}
                </Checkbox>
              ),
            },
            {
              key: NotificationType.ClientRenewedAccessPeriod,
              label: (
                <Checkbox
                  onClick={(ev) => ev.stopPropagation()}
                  checked={selectedKeys.includes(NotificationType.ClientRenewedAccessPeriod)}
                >
                  {t(`notificationTypeTitle.${NotificationType.ClientRenewedAccessPeriod}`)}
                </Checkbox>
              ),
            },
            {
              key: NotificationType.ClientsImportFinished,
              label: (
                <Checkbox
                  onClick={(ev) => ev.stopPropagation()}
                  checked={selectedKeys.includes(NotificationType.ClientsImportFinished)}
                >
                  {t(`notificationTypeTitle.${NotificationType.ClientsImportFinished}`)}
                </Checkbox>
              ),
            },
            {
              key: NotificationType.ClientCancelSubscriptionAtPeriodEnd,
              label: (
                <Checkbox
                  onClick={(ev) => ev.stopPropagation()}
                  checked={selectedKeys.includes(NotificationType.ClientCancelSubscriptionAtPeriodEnd)}
                >
                  {t(`notificationTypeTitle.${NotificationType.ClientCancelSubscriptionAtPeriodEnd}`)}
                </Checkbox>
              ),
            },
            {
              key: NotificationType.ClientResumeSubscriptionAtPeriodEnd,
              label: (
                <Checkbox
                  onClick={(ev) => ev.stopPropagation()}
                  checked={selectedKeys.includes(NotificationType.ClientResumeSubscriptionAtPeriodEnd)}
                >
                  {t(`notificationTypeTitle.${NotificationType.ClientResumeSubscriptionAtPeriodEnd}`)}
                </Checkbox>
              ),
            },
            {
              key: NotificationType.ClientManualPaymentCreated,
              label: (
                <Checkbox
                  onClick={(ev) => ev.stopPropagation()}
                  checked={selectedKeys.includes(NotificationType.ClientManualPaymentCreated)}
                >
                  {t(`notificationTypeTitle.${NotificationType.ClientManualPaymentCreated}`)}
                </Checkbox>
              ),
            },
            {
              key: NotificationType.ClientManualPaymentRenewal,
              label: (
                <Checkbox
                  onClick={(ev) => ev.stopPropagation()}
                  checked={selectedKeys.includes(NotificationType.ClientManualPaymentRenewal)}
                >
                  {t(`notificationTypeTitle.${NotificationType.ClientManualPaymentRenewal}`)}
                </Checkbox>
              ),
            },
            {
              key: NotificationType.ClientTerminated,
              label: (
                <Checkbox
                  onClick={(ev) => ev.stopPropagation()}
                  checked={selectedKeys.includes(NotificationType.ClientTerminated)}
                >
                  {t(`notificationTypeTitle.${NotificationType.ClientTerminated}`)}
                </Checkbox>
              ),
            },
          ]
        : []),
      {
        key: NotificationType.ClientSentVideoRecording,
        label: (
          <Checkbox
            onClick={(ev) => ev.stopPropagation()}
            checked={selectedKeys.includes(NotificationType.ClientSentVideoRecording)}
          >
            {t(`notificationTypeTitle.${NotificationType.ClientSentVideoRecording}`)}
          </Checkbox>
        ),
      },
      {
        key: NotificationType.ClientAddedBodyMeasurements,
        label: (
          <Checkbox
            onClick={(ev) => ev.stopPropagation()}
            checked={selectedKeys.includes(NotificationType.ClientAddedBodyMeasurements)}
          >
            {t(`notificationTypeTitle.${NotificationType.ClientAddedBodyMeasurements}`)}
          </Checkbox>
        ),
      },
      {
        key: NotificationType.ClientAddedWeightMeasurement,
        label: (
          <Checkbox
            onClick={(ev) => ev.stopPropagation()}
            checked={selectedKeys.includes(NotificationType.ClientAddedWeightMeasurement)}
          >
            {t(`notificationTypeTitle.${NotificationType.ClientAddedWeightMeasurement}`)}
          </Checkbox>
        ),
      },
      {
        key: NotificationType.ClientAddedBodyPhotos,
        label: (
          <Checkbox
            onClick={(ev) => ev.stopPropagation()}
            checked={selectedKeys.includes(NotificationType.ClientAddedBodyPhotos)}
          >
            {t(`notificationTypeTitle.${NotificationType.ClientAddedBodyPhotos}`)}
          </Checkbox>
        ),
      },
      {
        key: NotificationType.ClientRateWorkout,
        label: (
          <Checkbox
            onClick={(ev) => ev.stopPropagation()}
            checked={selectedKeys.includes(NotificationType.ClientRateWorkout)}
          >
            {t(`notificationTypeTitle.${NotificationType.ClientRateWorkout}`)}
          </Checkbox>
        ),
      },
      {
        key: NotificationType.ClientProgramEndingIn1Day,
        label: (
          <Checkbox
            onClick={(ev) => ev.stopPropagation()}
            checked={selectedKeys.includes(NotificationType.ClientProgramEndingIn1Day)}
          >
            {t(`notificationTypeTitle.${NotificationType.ClientProgramEndingIn1Day}`)}
          </Checkbox>
        ),
      },
      {
        key: NotificationType.ClientProgramEndingIn1Week,
        label: (
          <Checkbox
            onClick={(ev) => ev.stopPropagation()}
            checked={selectedKeys.includes(NotificationType.ClientProgramEndingIn1Week)}
          >
            {t(`notificationTypeTitle.${NotificationType.ClientProgramEndingIn1Week}`)}
          </Checkbox>
        ),
      },
      {
        key: NotificationType.ClientSurveyAnswered,
        label: (
          <Checkbox
            onClick={(ev) => ev.stopPropagation()}
            checked={selectedKeys.includes(NotificationType.ClientSurveyAnswered)}
          >
            {t(`notificationTypeTitle.${NotificationType.ClientSurveyAnswered}`)}
          </Checkbox>
        ),
      },
      {
        key: NotificationType.ClientSurveyAnswersUpdated,
        label: (
          <Checkbox
            onClick={(ev) => ev.stopPropagation()}
            checked={selectedKeys.includes(NotificationType.ClientSurveyAnswersUpdated)}
          >
            {t(`notificationTypeTitle.${NotificationType.ClientSurveyAnswersUpdated}`)}
          </Checkbox>
        ),
      },
    ];

    const handleOpenChange: DropdownProps["onOpenChange"] = (nextOpen, info) => {
      if (info.source === "trigger" || nextOpen) {
        setOpen(nextOpen);
      }
    };

    return (
      <Space size={4}>
        {selectedKeys.length > 0 && (
          <Button onClick={() => setSelectedKeys([])} type="link">
            {t("common:button.reset")}
          </Button>
        )}
        <Dropdown
          onOpenChange={handleOpenChange}
          open={open}
          menu={{
            items: filters,
            selectable: true,
            selectedKeys,
            multiple: true,
            onDeselect: ({ selectedKeys }) => {
              setSelectedKeys(selectedKeys as NotificationType[]);
            },
            onSelect: ({ selectedKeys }) => {
              setSelectedKeys(selectedKeys as NotificationType[]);
            },
          }}
        >
          <Button type={selectedKeys.length ? "primary" : undefined} icon={<FilterOutlined />} />
        </Dropdown>
      </Space>
    );
  },
);

const NotificationsDrawer: FunctionComponent<Props> = ({ toggleDrawer, isOpen }) => {
  const { t } = useTranslation(["dashboard", "common"]);
  const dispatch = useAppDispatch();
  const { isTrainer } = useUserRole();
  const [selectedKeys, setSelectedKeys] = useState<NotificationType[]>(
    isTrainer
      ? [
          NotificationType.ClientPurchaseProduct,
          NotificationType.ProductPaymentRequiredAction,
          NotificationType.ClientRenewedSubscription,
          NotificationType.ClientRenewedAccessPeriod,
          NotificationType.ClientsImportFinished,
          NotificationType.ClientCancelSubscriptionAtPeriodEnd,
          NotificationType.ClientResumeSubscriptionAtPeriodEnd,
          NotificationType.ClientTerminated,
          NotificationType.ClientManualPaymentCreated,
          NotificationType.ClientManualPaymentRenewal,
        ]
      : [],
  );
  const [activeTab, setActiveTab] = React.useState<"unread" | "all">("unread");
  const { totalUnread, listStatus, notifications, page, totalPages } = useAppSelector((store) => store.notifications);
  const [fetchingNextPage, setFetchingNextPage] = useState(false);

  useEffect(() => {
    void dispatch(notificationsActions.subscribeToNotifications(activeTab, selectedKeys));

    return () => {
      void dispatch(notificationsActions.unsubscribeFromNotifications());
    };
  }, [dispatch, activeTab, selectedKeys]);

  const onLoadMore = async () => {
    setFetchingNextPage(true);
    await dispatch(notificationsActions.fetchNotifications({ page: page + 1, type: selectedKeys, filter: activeTab }));
    setFetchingNextPage(false);
  };

  const renderContent = () => {
    if (listStatus === RequestStatus.FAILED) {
      return (
        <div className="p-4">
          <Alert type="error" showIcon message={t("errors.cannotFetchNotificationsList")} />
        </div>
      );
    }

    const loadMore =
      (listStatus === RequestStatus.SUCCESS || listStatus === RequestStatus.SUBSCRIBED) && page < totalPages ? (
        <div
          style={{
            textAlign: "center",
            marginTop: 12,
            marginBottom: 12,
            height: 32,
            lineHeight: "32px",
          }}
        >
          <Button onClick={onLoadMore} loading={fetchingNextPage}>
            Pobierz więcej
          </Button>
        </div>
      ) : null;

    return (
      <List
        itemLayout="horizontal"
        dataSource={notifications as (ClientNotifications | OwnerNotifications | TrainerNotifications)[]}
        loading={listStatus === RequestStatus.FETCHING || listStatus === RequestStatus.SUBSCRIBING}
        renderItem={(notification) => <NotificationItem notification={notification} closeDrawer={toggleDrawer} />}
        loadMore={loadMore}
        locale={{
          emptyText: (
            <Empty
              className="mt-12"
              description={t(activeTab === "unread" ? "notificationsDrawer.emptyUnread" : "notificationsDrawer.empty")}
            />
          ),
        }}
      />
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "unread",
      label: (
        <Space>
          {t("notificationsDrawer.unread")}
          <Badge count={totalUnread} overflowCount={99} />
        </Space>
      ),
      children: renderContent(),
    },
    {
      key: "all",
      label: <span>{t("notificationsDrawer.all")}</span>,
      children: renderContent(),
    },
  ];

  const dropdown: MenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: t("notificationsDrawer.markAllAsRead"),
        icon: <CheckOutlined />,
        onClick: () => {
          void dispatch(notificationsActions.markAllAsRead());
        },
      },
    ],
    [t, dispatch],
  );

  return (
    <Drawer
      title={t("notificationsDrawer.title")}
      extra={
        totalUnread > 0 && (
          <Dropdown menu={{ items: dropdown }}>
            <Button type="text">
              <Space>
                <MoreOutlined className="rotate-90 text-xl" />
              </Space>
            </Button>
          </Dropdown>
        )
      }
      placement="right"
      onClose={toggleDrawer}
      open={isOpen}
      width={450}
      bodyStyle={{ padding: 0, paddingTop: 0, height: "auto" }}
      className="drawer max-w-full"
    >
      <Tabs
        tabBarStyle={{
          padding: "0 16px",
          marginBottom: 0,
          backgroundColor: "white",
        }}
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key as "unread" | "all")}
        items={items}
        tabBarExtraContent={<NotificationFilter selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} />}
      />
    </Drawer>
  );
};

export default NotificationsDrawer;
