import { createAsyncThunk } from "@reduxjs/toolkit";

import { getErrorMessage } from "@fitness-app/utils";

import { type AsyncThunkCreator } from "../../../index";
import { PRODUCT_CLIENT_REDUCER } from "../types";

type Payload = {
  sendInvoiceToClient: boolean;
  updateClientData?: boolean;
  orderId: string;
  productId: string;
  ownerId: string;
  issueReceipt: boolean;
};

export const issueInvoice = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${PRODUCT_CLIENT_REDUCER}/issueInvoice`,
  async (payload, { rejectWithValue, extra: { invoicesApi } }) => {
    try {
      await invoicesApi.post("/retry-issuing-invoice", payload);
    } catch (e) {
      return rejectWithValue(getErrorMessage(e));
    }
  },
);
