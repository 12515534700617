import { Alert, Divider, Space, Switch, Tag, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import truncate from "lodash.truncate";
import { CreditCard, Dumbbell, Salad } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { traineeActions, traineeProgramActions } from "@fitness-app/app-store";
import { ProductDurationType } from "@fitness-app/data-models/entities/Product";
import { type SubscriptionProductClient } from "@fitness-app/data-models/entities/ProductClient";
import { type TraineeProfile } from "@fitness-app/data-models/entities/Trainee";

import ListItem from "~/components/ListItem/ListItem";
import { useLoading } from "~/hooks/useLoading";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface TraineeAccessProps {
  trainee: TraineeProfile;
  shouldDisableOption: boolean;
}

export const ScheduledAccessTag = ({
  scheduledAccessPeriod,
}: {
  scheduledAccessPeriod: SubscriptionProductClient["scheduledAccessPeriod"];
}) => {
  if (!scheduledAccessPeriod?.[0]) {
    return null;
  }

  return (
    <Tooltip
      title={
        <span>
          Dostęp przedłużony od {dayjs.unix(scheduledAccessPeriod[0].start_date).format("DD.MM.YYYY")} do{" "}
          {dayjs.unix(scheduledAccessPeriod.at(-1)?.end_date || 0).format("DD.MM.YYYY")}
        </span>
      }
    >
      <Tag color="green">
        Dostęp przedłużony (start {dayjs.unix(scheduledAccessPeriod[0].start_date).format("DD.MM.YYYY")})
      </Tag>
    </Tooltip>
  );
};

const AccessPeriod = ({ productClient, email }: { productClient: TraineeProfile["productClient"]; email: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("trainees");

  if (!productClient) {
    return null;
  }

  if (
    productClient.accessPeriod &&
    productClient.accessPeriod?.type === "recurring" &&
    productClient.accessPeriod.installments
  ) {
    return (
      <div className="space-y-1">
        <Typography.Link onClick={() => navigate(`/products/${productClient.productId}/clients/${email}`)}>
          {productClient.accessPeriod.startAt
            ? `${dayjs.unix(productClient.accessPeriod.startAt).format("DD.MM.YYYY")} - `
            : "do "}
          {productClient.accessPeriod?.endDate || productClient.accessPeriod?.installmentEndsAt
            ? dayjs
                .unix(productClient.accessPeriod.installmentEndsAt || productClient.accessPeriod.endDate!)
                .format("DD.MM.YYYY")
            : "- bezterminowo"}
        </Typography.Link>
        <ScheduledAccessTag scheduledAccessPeriod={productClient.scheduledAccessPeriod} />
      </div>
    );
  }

  if (productClient.accessPeriod) {
    return (
      <div className="space-y-1">
        <Typography.Link onClick={() => navigate(`/products/${productClient.productId}/clients/${email}`)}>
          {productClient.accessPeriod.currentPeriodStart
            ? `${dayjs.unix(productClient.accessPeriod.currentPeriodStart).format("DD.MM.YYYY")} - `
            : "do "}
          {productClient.accessPeriod?.currentPeriodEnd
            ? dayjs.unix(productClient.accessPeriod.currentPeriodEnd).format("DD.MM.YYYY")
            : "- bezterminowo"}
        </Typography.Link>
        <ScheduledAccessTag scheduledAccessPeriod={productClient.scheduledAccessPeriod} />
      </div>
    );
  }

  if (productClient.productDuration) {
    return (
      <Typography.Link onClick={() => navigate(`/products/${productClient.productId}/clients/${email}`)}>
        {productClient.productDuration.type === ProductDurationType.LastsFor
          ? `${t(`products:recurring.${productClient.productDuration.unit}`, {
              count: productClient.productDuration.count,
            })} - ${dayjs.unix(productClient.productDuration.startAt).format("DD.MM.YYYY")} - ${dayjs
              .unix(productClient.productDuration.endAt)
              .format("DD.MM.YYYY")}`
          : productClient.productDuration.type === ProductDurationType.UntilDate
          ? `${dayjs.unix(productClient.productDuration.startAt).format("DD.MM.YYYY")} - ${dayjs
              .unix(productClient.productDuration.endAt)
              .format("DD.MM.YYYY")}`
          : t(`products:productDurationType.${productClient.productDuration.type}`)}
      </Typography.Link>
    );
  }

  return (
    <Typography.Link onClick={() => navigate(`/products/${productClient.productId}/clients/${email}`)}>
      {t("tableHeader.connectedProduct")}: {productClient.product.name}
    </Typography.Link>
  );
};

const TraineeAccess = ({ trainee, shouldDisableOption }: TraineeAccessProps) => {
  const { t } = useTranslation("trainees");
  const { selectedProgram } = useAppSelector((store) => store.traineeProgram);
  const navigate = useNavigate();
  const [loading, startLoading, stopLoading] = useLoading();
  const [loadingNutrition, startNutritionLoading, stopNutritionLoading] = useLoading();
  const dispatch = useAppDispatch();

  const toggleProgramAccess = async (checked: boolean) => {
    if (selectedProgram) {
      startLoading();
      await dispatch(
        traineeProgramActions.toggleProgramAccess({
          traineeId: trainee.id,
          programId: selectedProgram.id,
          hasAccessToProgram: checked,
        }),
      );
      stopLoading();
    }
  };

  const toggleNutritionAccess = async (checked: boolean) => {
    if (trainee.nutrition) {
      startNutritionLoading();
      await dispatch(
        traineeActions.updateTrainee({
          id: trainee.id,
          profile: {
            hasActiveNutrition: checked,
          },
        }),
      );
      stopNutritionLoading();
    }
  };

  return (
    <>
      <ListItem
        title={t<string>("access.paid")}
        desc={<AccessPeriod email={trainee.email} productClient={trainee.productClient} />}
        iconPath={<CreditCard />}
        endContent={<Switch checked={trainee.productClient?.status === "active"} disabled />}
      />
      <Divider />
      <ListItem
        title="Plan treningowy"
        desc={
          !selectedProgram ? (
            t<string>("access.lackOfPlan")
          ) : (
            <Tooltip title={selectedProgram.name}>
              <Typography.Link
                strong
                onClick={() => navigate(`/trainee/details/${trainee.id}?tab=programs`)}
                style={{
                  maxWidth: "88%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {truncate(selectedProgram.name, { length: 28 })}
              </Typography.Link>
            </Tooltip>
          )
        }
        iconPath={<Dumbbell />}
        endContent={
          <Switch
            checked={Boolean(trainee.hasActiveTrainingProgram)}
            onClick={toggleProgramAccess}
            loading={loading}
            disabled={!trainee.activeTrainingProgramId || shouldDisableOption}
          />
        }
      />
      {!trainee.hasActiveTrainingProgram && trainee.activeTrainingProgramId ? (
        <Alert message="Plan nie jest widoczny dla klienta" banner />
      ) : null}
      <Divider />
      <ListItem
        title={t<string>("access.mealsPlan")}
        desc={
          !trainee.nutrition ? (
            t<string>("access.lackOfPlan")
          ) : (
            <Tooltip title={trainee.nutrition.name}>
              <Typography.Link
                strong
                onClick={() =>
                  navigate(`/trainee/details/${trainee.id}?tab=nutrition&planId=${trainee?.nutrition?.id}`)
                }
                style={{
                  maxWidth: "88%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {truncate(trainee.nutrition.name, { length: 28 })}
              </Typography.Link>
            </Tooltip>
          )
        }
        iconPath={<Salad />}
        endContent={
          <Space direction="vertical">
            <Switch
              checked={Boolean(trainee.hasActiveNutrition)}
              disabled={!trainee.activeMealsPlanId || shouldDisableOption}
              onClick={toggleNutritionAccess}
              loading={loadingNutrition}
            />
          </Space>
        }
      />
      {!trainee.hasActiveNutrition && trainee.nutrition ? (
        <Alert message="Dieta nie jest widoczna dla klienta" banner />
      ) : null}
    </>
  );
};

export default TraineeAccess;
