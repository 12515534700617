import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Card, Input, Space, Switch, Table, Typography } from "antd";
import { type TableProps } from "antd/es/table";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";

import { nutritionActions, RequestStatus } from "@fitness-app/app-store";
import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";

import ModalForm from "~/components/ModalForm/ModalForm";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useEntityChange } from "~/hooks/useEntityChange";
import AddIngredientForm from "~/modules/Nutrition/AddIngredientForm/AddIngredientForm";
import { transformModelToEntity } from "~/modules/Nutrition/AddIngredientForm/transformModelToEntity";
import { type AddIngredientFormModel } from "~/modules/Nutrition/AddIngredientForm/types";
import { useEditIngredient } from "~/modules/Nutrition/hooks/useEditIngredient";
import { useNutritionCategories } from "~/modules/Nutrition/hooks/useNutritionCategories";
import { useNutritionMeasures } from "~/modules/Nutrition/hooks/useNutritionMeasures";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const NutritionIngredients = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("nutrition");
  const [searchValue, setSearchValue] = useState("");
  const [showIngredientForm, toggleIngredientForm] = useState(false);
  const {
    ingredients,
    ingredientsPerPage,
    ingredientsTotalPages,
    ingredientSearchTerm,
    ingredientsStatus,
    ingredientsPage,
    filteredCategories,
  } = useAppSelector((state) => state.nutrition);
  const { categoryMap, categories } = useNutritionCategories();
  const [loading, onSuccess, onFailure, onStart] = useEntityChange();
  const user = useAppSelector((state) => state.user.data);
  const { measuresMap } = useNutritionMeasures();
  const { isTrainer, capabilities } = useUserRole();
  const [showIngredientsForVerification, setShowIngredientsForVerification] = useState(false);
  const { showEditIngredientModal, setEditModel, closeEditModal, editFormModel, saveUpdatedIngredient, updating } =
    useEditIngredient();

  useEffect(() => {
    if (!showIngredientsForVerification) {
      void dispatch(nutritionActions.fetchIngredients());
    } else {
      void dispatch(nutritionActions.fetchIngredients({ verified: false, source: "client_app" }));
    }
  }, [dispatch, showIngredientsForVerification]);

  const onClear = () => {
    setSearchValue("");
    dispatch(nutritionActions.setFilteredCategories([]));
    dispatch(nutritionActions.clearSearchingIngredient());
  };

  const onSearch = (value: string) => {
    const trimmed = value.trim();
    if (!trimmed) {
      if (ingredientSearchTerm) {
        onClear();
      }
      return;
    }
    void dispatch(nutritionActions.searchIngredient({ searchTerm: trimmed }));
  };

  const handleChange: TableProps<Ingredient>["onChange"] = (pagination, filters) => {
    if (!isEqual(filteredCategories, filters.mainCategory)) {
      dispatch(nutritionActions.setFilteredCategories((filters.mainCategory as number[]) || []));

      if (ingredientSearchTerm) {
        void dispatch(
          nutritionActions.searchIngredient({
            searchTerm: ingredientSearchTerm,
          }),
        );
      } else {
        void dispatch(
          nutritionActions.fetchIngredients(
            showIngredientsForVerification ? { verified: false, source: "client_app" } : undefined,
          ),
        );
      }
    } else {
      if (pagination.current !== ingredientsPage) {
        void dispatch(
          nutritionActions.fetchIngredients({
            page: pagination.current,
            ...(showIngredientsForVerification ? { verified: false, source: "client_app" } : {}),
          }),
        );
      }
    }
  };

  const handleAddIngredient = async (model: AddIngredientFormModel) => {
    onStart();
    try {
      await dispatch(
        nutritionActions.addIngredient({
          ingredient: transformModelToEntity(model, measuresMap, user?.id),
        }),
      ).unwrap();
      onSuccess();
      toggleIngredientForm(false);
    } catch (e) {
      onFailure();
    }
  };

  return (
    <>
      <Card
        title={
          isTrainer || capabilities?.canAcceptNutritionIngredients ? (
            <Space size={8}>
              <Typography.Text>Pokaż produkty do weryfikacji</Typography.Text>
              <Switch checked={showIngredientsForVerification} onChange={setShowIngredientsForVerification} />
            </Space>
          ) : null
        }
        extra={
          <Button onClick={() => toggleIngredientForm(true)} type="primary">
            {t("addNewProduct")}
          </Button>
        }
      >
        <Space direction="vertical" style={{ width: "100%" }} size={24}>
          <div className="flex w-full items-center">
            <Input.Search
              disabled={showIngredientsForVerification}
              placeholder={t<string>("search")}
              onSearch={onSearch}
              loading={ingredientsStatus === RequestStatus.SEARCHING}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              style={{ width: 300, margin: "20px 0" }}
            />
            {Boolean(ingredientSearchTerm) && (
              <Button onClick={onClear} type="link">
                {t("reset")}
              </Button>
            )}
          </div>
          <Table
            dataSource={ingredients}
            scroll={{ x: true }}
            rowKey="id"
            loading={ingredientsStatus === RequestStatus.FETCHING}
            pagination={{
              pageSize: ingredientsPerPage,
              total: ingredientsTotalPages * ingredientsPerPage,
              current: ingredientsPage,
              showSizeChanger: false,
            }}
            onChange={handleChange}
          >
            <Table.Column<Ingredient>
              title={t("ingredientsTable.name")}
              dataIndex="name"
              key="name"
              render={(name: string, row) => `${name} ${row.brand ? `(${row.brand})` : ""}`}
            />
            <Table.Column<Ingredient>
              title={t("ingredientsTable.category")}
              dataIndex="mainCategory"
              key="mainCategory"
              render={(category: string) => t(`categories.${categoryMap[category || "16"]?.name}`)}
              filterMode="tree"
              filterSearch
              filteredValue={filteredCategories}
              filters={categories}
            />
            <Table.Column<Ingredient>
              title={t("ingredientsTable.servingSize")}
              dataIndex={["packageSize"]}
              key="packageSize"
              render={(packageSize: number) => packageSize || 100}
            />
            <Table.Column<Ingredient>
              title={t("ingredientsTable.calories")}
              dataIndex={["nutrients", "calories"]}
              key="calories"
            />
            <Table.Column title={t("ingredientsTable.protein")} dataIndex={["nutrients", "protein"]} key="protein" />
            <Table.Column title={t("ingredientsTable.carbs")} dataIndex={["nutrients", "carbohydrates"]} key="carbs" />
            <Table.Column title={t("ingredientsTable.fat")} dataIndex={["nutrients", "fat"]} key="fat" />
            {showIngredientsForVerification && (
              <Table.Column<Ingredient>
                title={t("ingredientsTable.verified")}
                dataIndex="verified"
                key="verified"
                render={(isRequired: boolean) =>
                  isRequired ? (
                    <CheckOutlined style={{ color: "#25b10f" }} />
                  ) : (
                    <CloseOutlined style={{ color: "#ED1C24" }} />
                  )
                }
              />
            )}

            {showIngredientsForVerification && (
              <Table.Column<Ingredient>
                title="Opcje"
                dataIndex="action"
                key="action"
                render={(_, row) => {
                  if (row.verified) {
                    return null;
                  }
                  return (
                    <Space direction="horizontal">
                      <Button onClick={() => setEditModel(row)} type="link">
                        {t("common:button.edit")}
                      </Button>
                      <Button
                        danger
                        onClick={() => {
                          void dispatch(nutritionActions.deleteIngredient(row.id));
                        }}
                        type="link"
                      >
                        {t("common:button.delete")}
                      </Button>
                    </Space>
                  );
                }}
              />
            )}
          </Table>
        </Space>
      </Card>
      <ModalForm
        open={showIngredientForm}
        onCancel={() => toggleIngredientForm(false)}
        title={t("ingredientForm.title")}
        width={900}
        loading={!!loading}
      >
        <AddIngredientForm onSubmit={handleAddIngredient} />
      </ModalForm>

      <ModalForm
        open={showEditIngredientModal}
        onCancel={closeEditModal}
        title={t("ingredientForm.editTitle")}
        width={900}
        loading={!!updating}
      >
        <AddIngredientForm model={editFormModel} onSubmit={saveUpdatedIngredient} />
      </ModalForm>
    </>
  );
};

export default NutritionIngredients;
