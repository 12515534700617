import { type Database } from "@fitness-app/supabase";

export type TeamMemberEntity = Database["public"]["Tables"]["team_member"]["Row"];
export type TeamMemberAssignedEntity = Database["public"]["Tables"]["team_member_assigned"]["Row"];
export type TeamMember = Omit<TeamMemberEntity, "specializationTags" | "variables" | "capabilities"> & {
  specializationTags: string[] | null;
  variables: Record<string, string | number> | null;
  capabilities: null | {
    nutritionGenerator?: boolean;
    onlyForVerification?: boolean;
    canAcceptNutritionIngredients?: boolean;
  };
};

export type TeamMemberRoleEnum = Database["public"]["Enums"]["TeamMemberRole"];
export const TeamMemberRole: Record<
  Database["public"]["Enums"]["TeamMemberRole"],
  Database["public"]["Enums"]["TeamMemberRole"]
> = {
  SHARED_TRAINER: "SHARED_TRAINER",
  REGULAR_TRAINER: "REGULAR_TRAINER",
  ACCOUNT_OWNER: "ACCOUNT_OWNER",
} as const;

export type TeamMemberStatusEnum = Database["public"]["Enums"]["TeamMemberStatus"];
export const TeamMemberStatus: Record<
  Database["public"]["Enums"]["TeamMemberStatus"],
  Database["public"]["Enums"]["TeamMemberStatus"]
> = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  PENDING: "PENDING",
  INVITED: "INVITED",
} as const;

export type AssignedTrainer = {
  createdAt?: string;
  trainer: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: TeamMemberStatusEnum;
    user: { avatarUrl: string | null; id: string } | null;
  };
};

export type AssignedTrainerWithId = AssignedTrainer & { assignedTrainerId: string };

export type TeamMemberWithUser = TeamMember & {
  user: {
    firstName: string;
    lastName: string;
    avatarUrl: string;
  } | null;
  assignedTrainees: { id: string }[];
  assignedTrainers: AssignedTrainer[];
};
